<template>
  <b-card title="List Campaign">
    <b-col cols="12">
      <b-row class="mb-2">
        <b-col cols="4">
          <b-button v-if="permission.add" variant="success" @click="toAddCampaign">Add Campaign</b-button>
        </b-col>
        <b-col cols="4"/>
        <b-col cols="4">
          <b-form-input
            v-model="search"
            type="search"
            placeholder="Type to Search"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <b-table 
            :items="campaigns"
            :fields="fields" 
            :filter="search"
            :current-page="currentPage"
            :per-page="perPage"
            responsive
            show-empty
          >
            <template #cell(actions)="row">
              <b-dropdown  id="dropdown-dropleft" variant="primary" size="sm" text="Menu" no-caret>
                <template #button-content>
                  <feather-icon size="1x" icon="MenuIcon" />
                </template>
                <b-dropdown-item style="text-align:center" v-if="permission.edit">
                  <b-button @click="toEditCampaign(row.item._id)" variant="outline-primary" class="mr-1">
                    <feather-icon icon="EditIcon"/>
                  </b-button>
                </b-dropdown-item>
                <b-dropdown-item style="text-align:center" v-if="permission.delete">
                  <b-button @click="doDelete(row.item._id)" variant="danger" class="mr-1">
                    <feather-icon icon="Trash2Icon"/>
                  </b-button>
                </b-dropdown-item>
              </b-dropdown>
            </template>
            <template #cell(startDate)="row">
              {{dates(row.item.startDate)}}
            </template>
            <template #cell(endDate)="row">
              {{dates(row.item.endDate)}}
            </template>
          </b-table>
        </b-col>

        <b-col cols="12">
          <b-pagination
            :total-rows="dataSearch"
            :per-page="perPage"
            v-model="currentPage"
            style="float: right"
          />
        </b-col>
      </b-row>

    </b-col>
  </b-card>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { dateFormat, userAccess } from "@/utils/utils.js";

export default {
  data() {
    return {
      fields: [
        { key: "name", label: "Name", filterable: true, sortable: true },
        { key: "startDate", label: "Start Date", filterable: true, sortable: true },
        { key: "endDate", label: "End Date",filterable: true, sortable: true },
        { key: "status", label: "Status",filterable: true, sortable: true },
        { key: "actions", label: "Actions" },
      ],
      search: '',
      currentPage: 1,
      perPage: 5
    };
  },
  mounted() {
    this.getCampaigns();
  },
  methods: {
    ...mapActions({
      getCampaigns: "campaign/getCampaigns",
      deleteCampaign: "campaign/deleteCampaign",
    }),
    dates(date){
      return dateFormat(date)
    },
    toAddCampaign() {
      this.$router.push("/promo/campaign/add");
    },
    doDelete(id) {
      this.deleteCampaign(id).then(() => {
        this.getCampaigns();
      }).catch((error) =>{
        // console.error(error)
        this.$bvToast.toast(`${error.response.data.errors[0].message}`, {
          title: "Fail",
          variant: "danger",
          solid: true,
        });
      });
    },
    toEditCampaign(id) {
      this.$router.push(`/promo/campaign/edit/${id}`);
    },
  },
  computed: {
    ...mapState({
      campaigns: (state) => state.campaign.campaigns,
    }),
    permission() {
      return userAccess("Campaign", "warranty_master_menu");
    },
    dataSearch(){
      let paginationLength;
      paginationLength = this.campaigns.filter(el => {
          return el.name.includes(this.search);
      })
      if(this.search != ''){
          return paginationLength.length
      }else{
          return this.campaigns.length
      }
    },
  },
  created() {
    document.title = 'Campaign | RSP'
  },
};
</script>
